<template>
  <div class="csv-importer">
    <alert-modal ref="alert" />

    <input
      type="file"
      name="csv"
      id="csv"
      accept="text/csv"
      ref="input"
      @input="onUploadCSV"
    />
    <div class="left">
      <h4>{{ title }}</h4>
      <div class="columns-container">
        <span class="title">Column Headers:</span>
        <span v-for="(column, index) in columns" class="column" :key="index">
          {{ column.name }}
          <span class="required" v-if="column.required"> *</span>
        </span>
      </div>
      <span v-if="error" class="error">
        {{ error }}
      </span>

      <!-- <p>
        <b>Note:</b>
        Rows that are missing necessary information will be ignored.
      </p> -->

      <p>
        Click to
        <a @click="downloadSampleCSV" style="cursor: pointer">Download</a>
        CSV template.
      </p>
    </div>
    <div class="right">
      <div v-if="fileName" class="file-info">
        <span>{{ truncatedFileName }}</span>
        <span>({{ importCount }} items)</span>
      </div>
      <diyobo-button type="primary" txt="Import" @click="onClickImport" />
    </div>

    <div class="buttons" v-if="contacts">
      <diyobo-button txt="Cancel" type="deny" @click="cancel" />
      <diyobo-button type="primary" txt="Submit" @click="submit" />
    </div>
  </div>
</template>

<script>
import DiyoboButton from "@/components/DiyoboButton.vue";
import AlertModal from "@/components/modals/AlertModal.vue";
import Papa from "papaparse";

export default {
  name: "CSVImporter",
  components: {
    DiyoboButton,
    AlertModal,
  },
  props: {
    columns: {
      type: Array,
    },
    onlyRequiredData: {
      type: Boolean,
      default: false,
      description:
        "Will only return required columns data, will skip un required data columns.",
    },
    rowLimit: {
      type: Number,
      default: 1,
      description: "Used to limit data sent to backend.",
    },
    title: {
      type: String,
      default: "Import via CSV",
      description: "Title of CSV Importer",
    },
  },
  data() {
    return {
      importCount: 0,
      fileName: "",
      error: null,
      contacts: null,
      maxFileNameLength: 25,
      fileReader: null,
    };
  },
  computed: {
    properties() {
      return this.columns.map((a) => {
        return { [a.property]: a.name };
      });
    },
    truncatedFileName() {
      if (this.fileName.length <= this.maxFileNameLength) {
        return this.fileName;
      } else {
        // Get the length of the extension
        const extensionLength = this.fileName.split(".").pop().length;

        // Calculate the length of the filename excluding the extension
        const filenameLength = this.maxFileNameLength - 3 - extensionLength; // Subtract 3 for the ellipsis and the dot separator

        // Extract the filename without the extension
        const truncatedFilename = this.fileName.substring(0, filenameLength);

        // Concatenate the truncated filename with ellipsis and the extension
        return truncatedFilename + "..." + this.fileName.slice(-7);
      }
    },
  },
  methods: {
    onClickImport() {
      this.$refs.input.click();
    },
    onUploadCSV(event) {
      let csv = event.target.files[0];

      Papa.parse(csv, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          const contacts = [];
          const missingColumns = this.columns.filter(
            (c) => c.required && !result.meta.fields.includes(c.name)
          );
          const requiredColumns = this.columns.filter((c) => c.required);

          if (missingColumns.length > 0) {
            this.error =
              "Missing required columns: " +
              missingColumns.map((c) => c.name).join(", ");
            return;
          }

          this.error = null;

          // let validData = result.data.filter((row) => {
          //   return requiredColumns.every((header) => {
          //     return row.hasOwnProperty(header.name) && row[header.name];
          //   });
          // });

          if (result.data.length > this.rowLimit) {
            this.error = `Assigning ticket is greater than available ticket (${this.rowLimit})`;
            csv = "";
            this.fileName = "";
            return;
          }

          for (const item of result.data) {
            // Required fields added to data
            if (this.onlyRequiredData) {
              let rowData = {};
              requiredColumns.forEach((header) => {
                rowData[header.name] = item[header.name].trim();
              });
              contacts.push(rowData);
            } else {
              // All column fields added to data
              let rowData = {};

              this.columns.forEach((header) => {
                rowData[header.name] = item[header.name].trim();
              });
              contacts.push(item);
            }
          }

          this.importCount = contacts.length;

          this.fileName = csv.name;

          if (contacts.length > 50000) {
            this.error =
              "The maximum amount of contacts that can be imported is 50000";
            return;
          }
          this.contacts = contacts;
        },
      });
    },
    cancel() {
      this.contacts = null;
      this.importCount = 0;
      this.fileName = "";
      this.$refs.input.value = "";
    },
    submit() {
      let valid = this.validateData();
      if (valid) {
        this.$emit("input", [...this.contacts]);
        this.contacts = null;
        this.fileName = "";
        this.$refs.input.value = "";
        this.importCount = 0;
      }
    },
    validateData() {
      let errorMsg = "<p><b>Please review these errors: </b></p>";
      let errorDetected = false;
      this.contacts.forEach((contact, index) => {
        let errorMessages = [];
        this.columns.forEach((header) => {
          // rowData[header.name] = contact[header.name];
          if (header.required && !contact[header.property]) {
            errorMessages.push(header.name + " is required");
            errorDetected = true;
          }
        });

        if (errorMessages.length > 0) {
          let i = "";
          errorMessages.forEach((item, key) => {
            if (key + 1 == errorMessages.length) {
              i += item + ".";
            } else {
              i += item + ", ";
            }
          });
          errorMsg += `<p>Line ${index + 2} - ${i}</p>`;
        }
      });

      if (errorDetected) {
        this.$refs.alert.open({
          title: "Error Uploading",
          msg: errorMsg,
          closable: false,
          onConfirm: () => {
            window.location.reload();
          },
          onClose: () => {
            window.location.reload();
          },
        });
        return false;
      }

      return true;
    },
    downloadSampleCSV() {
      // Sample data for demonstration
      let columns = [];
      let exampleData = [];
      this.columns.forEach((item) => {
        columns.push(item.name);
        if (item.example) {
          exampleData.push(item.example);
        }
      });
      const data = [[columns], [exampleData]];

      // Convert data to CSV string
      const csvContent = data.map((row) => row.join(",")).join("\n");

      // Create a Blob containing the CSV data
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

      // Create a download link
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "sample-data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Your browser does not support the download attribute.");
      }
    },
  },
};
</script>

<style lang="less">
.modal-body {
  p {
    display: block;
  }
}
</style>
<style lang="less" scoped>
.csv-importer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid var(--dashboard-border);
  border-radius: 8px;
  box-shadow: var(--table-shadow);

  #csv {
    display: none;
  }

  .left {
    display: flex;
    flex-direction: column;
    p {
      font-size: 16px;
    }

    h4 {
      margin: 0 0 8px 0;
    }

    .title {
      margin-bottom: 5px;
      display: inline-flex;
    }

    .column {
      padding: 2px 10px;
      margin: 4px;
      background: var(--event-border);
      border-radius: 20px;
      // display: inline-flex;

      .required {
        color: red;
        font-size: 18px;
      }
    }

    .error {
      color: var(--error-red);
    }

    .columns-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .right {
    display: flex;
    align-items: flex-end;
    flex-direction: column-reverse;
    justify-content: space-evenly;
    max-width: 36%;

    .file-info {
      span {
        margin-right: 4px;
        word-break: break-all;
      }
    }
  }

  .buttons {
    margin: 18px 0;
    display: flex;
    justify-content: center;
    column-gap: 10px;
    width: 100%;
  }
}
</style>
