<template>
  <div class="dashboard">
    <div id="Content">
      <div class="content-inner">
        <tab-tote
          :tabs="navTabs"
          :value="navTabSelected"
          v-on:click="onClickNavTab"
        />
        <div class="divider"></div>
        <div v-if="navTabSelected == 0">
          <h4>My contact lists</h4>
          <Card>
            <section class="main-content">
              <div class="row">
                <div class="col half">
                  <FormulateInput
                    type="text"
                    label="Name of the list"
                    v-model="name"
                    validation="required"
                  />
                </div>
                <div class="col full csv-importer-wrapper">
                  <CSVImporter
                    ref="csv_import"
                    v-model="contactsUploaded"
                    :columns="column"
                    type="contact-list"
                    issuedTo="individual"
                    @input="validateForm()"
                    :rowLimit="100000"
                  />
                </div>
              </div>
            </section>
          </Card>
          <DataTable
            v-if="promoter"
            title="Contact lists"
            endpoint="/promoters/sales-list/get-all-promoter-contacts"
            :params="{ promoter_id: promoter._id }"
            ref="contactList"
            :csvMapper="csvMapper"
            :headers="this.tableContactsLists.columns"
            searchPlaceholder="Name or Email Search"
          >
          </DataTable>
        </div>
        <div v-if="navTabSelected == 1">
          <DataTable
            v-if="promoter"
            title="Contact list"
            endpoint="/promoters/sales-list/get-promoter-contacts"
            :params="{ promoter_id: promoter._id }"
            ref="table"
            :csvMapper="csvMapper"
            :headers="this.tableContent.columns"
            searchPlaceholder="Name or Email Search"
          >
          </DataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import TabTote from "@/components/TabTote.vue";
import DataTable from "@/components/DataTable.vue";
import CSVImporter from "@/components/CSVImporter.vue";

export default {
  name: "SaleList",
  components: {CSVImporter, DataTable, TabTote},
  data() {
    return {
      navTabs: ["My contact lists"],
      navTabSelected: 0,
      event: null,
      promoter: null,
      url: this.$route.params.event,
      tableContactsLists: {
        columns: [
          {text: "First Name", value: "first_name", width: "20%"},
          {text: "Last Name", value: "last_name", width: "20%"},
          {text: "Email", value: "email", align: "15%"},
          {text: "Phone", value: "phone", align: "15%"},
          {text: "List", value: 'list_name', align: "15%"},
          {text: "Has purchase", value: "has_purchase", align: "15%"}
        ]
      },
      tableContent: {
        columns: [
          {text: "First Name", value: "first_name", width: "20%"},
          {text: "Last Name", value: "last_name", width: "20%"},
          {text: "Email", value: "email", align: "20%"},
          {text: "Phone", value: "phone", align: "20%"},
          {text: "Has purchase", value: "has_purchase", align: "20%"}
        ]
      },
      name: '',
      contactsUploaded: [],
      column: [
        {
          name: "first_name",
          property: "first_name",
          required: true,
          example: "John"
        },
        {
          name: "last_name",
          property: "last_name",
          required: true,
          example: "Doe"
        },
        {
          name: "email",
          property: "email",
          required: true,
          example: "example@example.com",
          validate: 'email'
        },
        {name: "phone", property: "phone", example: "+12041233442"}
      ],
      processing: false
    }
  },
  methods: {
    setName(value) {
      this.name = value;
    },
    onClickNavTab(index) {
      this.navTabSelected = index;
    },
    csvMapper(contacts) {
      let contactList = [];

      contacts.forEach(contact => {
        let list = {
          "First name": contact.first_name,
          "Last name": contact.last_name,
          "Email": contact.email,
          "Phone": contact.phone,
          "Has purchase": contacts.has_purchased ? 'True' : 'False'
        };

        contactList.push(list);
      });

      return contactList;
    },
    downloadExamopleCSV() {
      // Sample data for demonstration
      let columns = [];
      let exampleData = [];
      this.column.forEach(item => {
        columns.push(item.name);
        exampleData.push(item.example);
      });
      const data = [[columns], [exampleData]];

      // Convert data to CSV string
      const csvContent = data.map(row => row.join(",")).join("\n");

      // Create a Blob containing the CSV data
      const blob = new Blob([csvContent], {type: "text/csv;charset=utf-8;"});

      // Create a download link
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Your browser does not support the download attribute.");
      }
    },
    goToDashboard() {
      this.$router.push("/dashboard");
    },
    validateForm() {
      if (this.name == '') {
        this.$toast('The name is required', {type: "error"});
        return;
      }
      if (this.contactsUploaded.length < 1) {
        return;
      }

      //send request for create contact
      this.$axios.post(`/promoters/sales-list/${this.url}/create`, {
        name: this.name,
        contacts: this.contactsUploaded
      }).then(response => {
        if (response.data.error) {
          this.$toast(response.data.error, {type: "error"});
        } else if (response.data.duplicate > 0) {
          this.$toast('Contacts was created successfully, but we ignored ' + response.data.duplicate + ' duplicate contacts.');
        } else {
          this.$toast('Contacts was created successfully.');
        }
      });
      this.contactsUploaded = [];
      this.name = '';
      this.$refs.contactList.fetchData();
      this.$forceUpdate();
      this.$refs.csv_import.resetData();
      this.processing = false;
    }
  },
  created() {
    this.$axios.post("/promoters/sales-list/get-promoter", {url: this.url}).then(response => {
      this.promoter = response.data.promoter;
      if (this.promoter.is_salesperson) {
        this.navTabs.push('Assigned contacts to Me');
      }
    });
  }
}
</script>

<style scoped lang="less">
.redirect-button {
  text-align: -webkit-right;

  .formulate-input[data-classification="button"] button {
    background: var(--secondary-green);
  }
}
</style>